<template>
  <div class="s-p-3">
    <b-modal id="modal-detail" centered hide-header hide-footer>
      <template #default="{ close }">
        <div v-if="currentTask" class="p-2 badge__detail" :style="colorObject">
          <button class="badge__detail__close" @click="close()">
            <img src="./images/close.svg" />
          </button>
          <div
            class="badge__detail__hero p-4"
            :class="currentTask.progress == 100 && 'active'"
          >
            <div class="badge__detail__hero__img w-50 m-auto">
              <img :src="currentTask.image_url || require(`./images/question.svg`)" />
            </div>

            <div class="badge__detail__hero__title mt-2">
              {{ currentTask.name }}
            </div>
          </div>
          <div class="py-3 border-bottom">
            <div class="font-weight-bold h6">任務</div>
            <div>{{ currentTask.description }}</div>

            <div class="badge__task__progress my-2">
              <div
                class="badge__task__progress__bar"
                :style="{ width: `${currentTask.progress}%` }"
              ></div>
            </div>
            <div class="text-right s-primary">{{ currentTask.progress }}%</div>
          </div>
          <div class="py-3">
            <div class="font-weight-bold h6">注意事項</div>
            <div>{{ currentTask.description }}</div>
          </div>
          <SharedButton>前往綁定</SharedButton>
        </div>
      </template>
    </b-modal>

    <b-card
      v-for="i in events"
      :key="i.id"
      class="badge w-100 mb-2 text-wrap"
      no-body
    >
      <div class="badge__hero" :style="{ background: i.bg }">
        <img :src="i.img_url" class="img-fluid badge__hero_img" />
        <div class="px-4 py-3">
          <div class="badge__progress">
            <div
              class="badge__progress__bar"
              :style="{ width: `${i.progress}%` }"
            ></div>
          </div>
          <div class="text-white mt-1 text-left">已達成 {{ i.progress }} %</div>
        </div>
      </div>
      <div class="px-3 pb-3">
        <div class="row py-3 border-bottom m-0" v-if="isEventDetail">
          <b-col v-for="(t, idx) in i.badges" :key="idx" cols="3" class="p-2">
            <button
              class="badge__task"
              :class="t.progress == 100 && 'active'"
              v-b-modal.modal-detail
              @click="goToBadgeDetail(i.id, t.id)"
            >
              <img :src="t.image_url || require(`./images/question.svg`)" />
            </button>
            <div
              class="badge__task__progress my-2"
              :class="t.progress == 100 && 'invisible'"
            >
              <div
                class="badge__task__progress__bar"
                :style="{ width: `${t.progress}%` }"
              ></div>
            </div>
            <div class="badge__task__name">{{ t.name }}</div>
          </b-col>
        </div>
        <hr />
        <div class="pt-3">
          <div class="d-flex justify-content-between align-items-center">
            <span class="badge__date"><span v-if="i.more">任務日期：</span>
              {{ i.start_at ?  moment(i.start_at).format('YYYY-MM-DD') : "即日起" }} ~ {{moment(i.end_at).format('YYYY-MM-DD')}}</span>
            <SharedButton
              v-if="!i.more"
              class="s-btn-outline-primary w-auto py-2"
              @click="goToEventDetail(i.id)"
              >MORE</SharedButton
            >
          </div>
          <div v-if="isEventDetail" class="badge__description mt-2">
            {{ i.description }}
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import SharedButton from "@/components/Page/Liff/Shared/Button";
import themeColor from "@/mixins/liff/themeColor";
import badgeEventApi from "@/apis/liff/v2/badge-event"
import { mapGetters } from "vuex";
import moment from "moment"

export default {
  mixins: [themeColor],
  components: { SharedButton },
  data() {
    return {
      themeConfigPage: "badge",
      badges: null,
      currentTask: null,
      events:[],
      //currentBadge: null,
    };
  },
  async mounted() {
    if (this.$route.name == "LiffBadgeEventList") {
      const { data } = await badgeEventApi.getEvents();
      this.events = data.map(ev => {
        return {
          ...ev,
          more: ev.badge && ev.badge.length > 0,
        }
      })
    } else if (this.isEventDetail) {
      await this.fetchEventDetail()
      if (this.$route.name == "LiffBadgeDetail") {
        this.setBadge()
        this.$bvModal.show("modal-detail");
      }
    }
    this.$root.$on('bv::modal::hide', () => {
      this.goToEventDetail(this.$route.params.event_id)
    })
  },
  methods: {
    moment,
    async fetchEventDetail() {
      const { data } = await badgeEventApi.getEvent(this.$route.params.event_id);
      this.events = [data];
      this.events[0].more = true;
    },
    setBadge() {
      this.currentTask = this.events[0].badges.find(b => b.id == this.$route.params.badge_id);
    },
    goToEventDetail(eventId) {
      return this.$router.push({
        name: 'LiffBadgeEventDetail',
        params: {
          org_code: this.$route.params.org_code,
          event_id: eventId,
        }
      }, () => {
        this.fetchEventDetail()
      })
    },
    goToBadgeDetail(eventId, badgeId) {
      return this.$router.push({
        name: 'LiffBadgeDetail',
        params: {
          org_code: this.$route.params.org_code,
          event_id: eventId,
          badge_id: badgeId,
        }
      }, () => {
        this.setBadge()
      })
    }
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
      getModule: "liffModule/getModule",
    }),
    isEventDetail() {
      return this.$route.name == "LiffBadgeEventDetail" || this.$route.name == "LiffBadgeDetail";
    }
  },
};
</script>

<style lang="scss" scoped>
.badge {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  font-weight: bold;

  &__date {
  }

  &__hero {
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  &__hero_img {
  }

  &__progress {
    background: white;
    border-radius: 1rem;
    box-shadow: 0px 0px 7px 0px rgba(44, 44, 46, 0.6);
    overflow: hidden;

    &__bar {
      background-color: #ffd600;
      height: 1rem;
      border-radius: 1rem;
      border: solid 3px white;
    }
  }

  &__description {
    color: #636366;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    text-align: start;
  }

  &__task {
    border-radius: 100%;
    aspect-ratio: 1;
    overflow: hidden;
    padding: 5px;
    border: 3px solid #c0c4cc;
    width: 100%;

    img {
      border-radius: 100%;
      object-fit: cover;
      max-width: 100%;
      width: 100%;
      aspect-ratio: 1;
      border: solid 1px #6699cc4d;
      background-color: #f2f2f7;
    }

    &.active {
      border-color: var(--s-primary);
    }

    &__name {
      font-size: 13px;
      font-weight: bold;
    }

    &__progress {
      background: #d9d9d9;
      border-radius: 5px;

      &__bar {
        background-color: #333;
        height: 5px;
        border-radius: 5px;
      }
    }
  }

  &__detail {
    &__close {
      position: absolute;
      top: -3rem;
      left: 50%;
      transform: translateX(-50%);
      border: none;
      border-radius: 100%;
      aspect-ratio: 1;
      padding: 10px;
      line-height: 0;
    }

    &__hero {
      background-image: url("images/badge_bg.png");
      background-size: contain;

      &__img {
        border-radius: 100%;
        aspect-ratio: 1;
        overflow: hidden;
        padding: 15px;
        border: 5px solid #c0c4cc;
        width: 100%;

        img {
          border-radius: 100%;
          object-fit: cover;
          max-width: 100%;
          width: 100%;
          aspect-ratio: 1;
          border: solid 1px #6699cc4d;
          background-color: #f2f2f7;
        }
      }

      &__title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
      }

      &.active {
        background-image: url("images/badge_bg.png");
        background-size: contain;

        .badge__detail__hero__img {
          border-color: var(--s-primary);
          box-shadow: 0px 0px 12px 0px rgba(102, 153, 204, 0.5);
        }

        .badge__detail__hero__title {
          color: var(--s-primary);
        }
      }
    }

    .badge__task__progress__bar {
      height: 8px;
      background-color: var(--s-primary);
    }
  }
}

::v-deep .modal-content {
  border-radius: 10px;
  font-size: 15px;
}
</style>
