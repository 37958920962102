import https from "./https";
import store from "@/store";

const badgeEvent = {
  async getEvents() {
    let response = {}
    const organization = store.state.liffGeneral.orgCode;
    response = await https.get(`${organization}/liff/badge-events/`);
    return response.data
  },

  async getEvent(eventId) {
    let response = {}
    const organization = store.state.liffGeneral.orgCode;
    response = await https.get(`${organization}/liff/badge-events/${eventId}`);
    return response.data
  },
};


export default badgeEvent;
